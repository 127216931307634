<template>
  <div class="py-10 bg-gray-50">
    <div class="container">
      <h2
        class="pb-3 text-2xl font-semibold text-center text-mesh-blue md:text-2xl lg:text-3xl"
      >
        Our Products Ranges
      </h2>
      <div class="bg-mesh-blue h-0.5 w-12 mx-auto"></div>

      <div id="product" class="pt-6 overflow-x-auto">
        <table class="table-fixed">
          <thead class="border border-gray-600">
            <tr class="text-red-900">
              <th
                class="w-1/2 py-2 text-gray-800 bg-yellow-100 border-r border-gray-600 "
              >
                Description
              </th>
              <th class="w-1/4 bg-blue-200 border-r border-gray-600">
                Ultimate Security
              </th>
              <th class="w-1/4 bg-green-200 border-r border-gray-600">
                Superior Security
              </th>
              <th class="w-1/4 bg-purple-200 border-r border-gray-600">
                Basic Security
              </th>
              <th class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Mozzy+
              </th>
              <th class="w-1/4 bg-yellow-200">Glass</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr class="border border-gray-600">
              <td class="py-2 pl-3 text-left border-r border-gray-600">
                Mesh Type
              </td>
              <td class="py-2 bg-blue-200 border-r border-gray-600">
                316 stainless steel
              </td>
              <td class="py-2 bg-green-200 border-r border-gray-600">
                304 stainless steel
              </td>
              <td class="py-2 bg-purple-200 border-r border-gray-600">
                304 stainless steel
              </td>
              <td class="py-2 border-r border-gray-600 bg-mesh-yellow">
                304 stainless steel
              </td>
              <td class="py-2 bg-yellow-200">Multi Shades</td>
            </tr>
            <tr class="border border-gray-600">
              <td class="py-2 pl-3 text-left border-r border-gray-600">
                Mesh Thickness
              </td>
              <td class="py-2 bg-blue-200 border-r border-gray-600">1.0mm</td>
              <td class="py-2 bg-green-200 border-r border-gray-600">0.9mm</td>
              <td class="py-2 bg-purple-200 border-r border-gray-600">0.8mm</td>
              <td class="py-2 border-r border-gray-600 bg-mesh-yellow">
                0.6mm
              </td>
              <td class="py-2 bg-yellow-200">6.38mm</td>
            </tr>
            <tr class="border border-gray-600">
              <td class="py-2 pl-3 text-left border-r border-gray-600">
                Aluminum Frame Thickness
              </td>
              <td class="py-2 bg-blue-200 border-r border-gray-600">2.50mm</td>
              <td class="py-2 bg-green-200 border-r border-gray-600">2.50mm</td>
              <td class="py-2 bg-purple-200 border-r border-gray-600">
                2.50mm
              </td>
              <td class="py-2 border-r border-gray-600 bg-mesh-yellow">
                1.1mm
              </td>
              <td class="py-2 bg-yellow-200">1.5mm</td>
            </tr>
            <tr class="border border-gray-600">
              <td class="py-2 pl-3 text-left border-r border-gray-600">
                Aluminum Track Thickness
              </td>
              <td class="py-2 bg-blue-200 border-r border-gray-600">3.00mm</td>
              <td class="py-2 bg-green-200 border-r border-gray-600">3.00mm</td>
              <td class="py-2 bg-purple-200 border-r border-gray-600">
                3.00mm
              </td>
              <td class="py-2 border-r border-gray-600 bg-mesh-yellow">
                1.1mm
              </td>
              <td class="py-2 bg-yellow-200">2.0mm</td>
            </tr>
          </tbody>
        </table>
        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <td class="py-2 text-lg font-bold text-gray-600 bg-yellow-100">
              LOCK SET
            </td>
          </thead>
        </table>
        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 border-r border-gray-600"></td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                European
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                Generic
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Generic
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Common
              </td>
              <td class="w-1/4 bg-yellow-200">Generic</td>
            </tr>
          </thead>
        </table>
        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <td class="py-2 text-lg font-bold text-gray-600 bg-yellow-100">
              LOCK POINT
            </td>
          </thead>
        </table>

        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Casement/Awning French Window
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                4 Point Button Lock Handle
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                4 Point Keyless handle
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Multi Point Keyless
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Single Point
              </td>
              <td class="w-1/4 bg-yellow-200">Multi Point</td>
            </tr>
          </thead>
          <tbody class="border border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Sliding Window
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                2-4 Point
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                2-4 Point
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Multi Point
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Single Point
              </td>
              <td class="w-1/4 bg-yellow-200">Multi Point</td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Hinged Door
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                5 Point Lock
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                5 Point Lock
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Multi Point
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Single Point
              </td>
              <td class="w-1/4 bg-yellow-200">Multi Point</td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                French Door
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                5 Point(Main),2 Point(Slave)
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                5 Point(Main),2 Point(Slave)
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Multi Point
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Single Point
              </td>
              <td class="w-1/4 bg-yellow-200">Multi Point</td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Sliding Door
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                3 Point, 4 Point
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                3 Point, 4 Point
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Multi Point
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Single Point
              </td>
              <td class="w-1/4 bg-yellow-200">Multi Point</td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                BI-Fold Door
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                5 Point(Main),2 Point(Slave)
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                5 Point(Main),2 Point(Slave)
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                Not Availabel
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                Not Availabel
              </td>
              <td class="w-1/4 bg-yellow-200">Multi Point</td>
            </tr>
          </tbody>
        </table>

        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <td
              class="py-2 text-lg font-bold text-gray-600 uppercase bg-yellow-100 "
            >
              Benefits
            </td>
          </thead>
        </table>

        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 py-2 pl-3 text-left border-r border-gray-600">
                Impact Resistance
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                <i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-yellow-200 border-b border-yellow-200"></td>
            </tr>
          </thead>
          <tbody class="border border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Visiblilty
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-yellow-200 border-b border-yellow-200"></td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Ventilation
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                50%
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">50%</td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">50%</td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">50%</td>
              <td class="w-1/4 bg-yellow-200 border-b border-yellow-200"></td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Mosquito Prevention
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                Yes
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">Yes</td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">Yes</td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">Yes</td>
              <td class="w-1/4 bg-yellow-200 border-b border-yellow-200">
                Not Applicable
              </td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Energy Saving
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-yellow-200 border-b border-yellow-200"></td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                UV Blockage
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">
                <i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i><i class="fas fa-star"></i
                ><i class="fas fa-star"></i>
              </td>
              <td class="w-1/4 bg-yellow-200"></td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">
                Custome Made
              </td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">
                Yes
              </td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">Yes</td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">Yes</td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">Yes</td>
              <td class="w-1/4 bg-yellow-200">Yes</td>
            </tr>
          </tbody>
        </table>

        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <td
              class="py-2 text-lg font-bold text-gray-600 uppercase bg-yellow-100 "
            >
              Warranty Period
            </td>
          </thead>
        </table>

        <table class="table-fixed">
          <tbody class="border border-t-0 border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">Mesh (Against Rust)</td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">20 Years</td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">20 Years</td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">10 Years</td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">10 Years</td>
              <td class="w-1/4 bg-yellow-200">Not Applicable</td>
            </tr>
            <tr class="text-sm border border-gray-600">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">Hardware</td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">10 Years</td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">6 Years</td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">3 Years</td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">6 Months</td>
              <td class="w-1/4 bg-yellow-200">5 Years</td>
            </tr>
          </tbody>
        </table>
        <table class="table-fixed">
          <thead class="border border-t-0 border-gray-600">
            <td
              class="py-2 text-lg font-bold text-gray-600 uppercase bg-yellow-100 "
            >
              Free Replacement
            </td>
          </thead>
        </table>
        <table class="table-fixed">
          <tbody class="border border-t-0 border-gray-600">
            <tr class="text-sm">
              <td class="w-1/2 pl-3 text-left border-r border-gray-600">Damage By Intruder(with police report)</td>
              <td class="w-1/4 py-2 bg-blue-200 border-r border-gray-600">2 Years</td>
              <td class="w-1/4 bg-green-200 border-r border-gray-600">2 Years</td>
              <td class="w-1/4 bg-purple-200 border-r border-gray-600">NIL</td>
              <td class="w-1/4 border-r border-gray-600 bg-mesh-yellow">NIL</td>
              <td class="w-1/4 bg-yellow-200">NIL</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
#product table{
    width: 1280px;
}

@media (min-width: 1024px){
  #product table{
    width: 100%;
}
}
</style>