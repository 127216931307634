<template>
    <div v-if="popupVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999] p-5"
        @click.self="close">
        <div class="bg-white shadow-md lg:max-w-[550px] max-w-[400px] flex items-center flex-col justify-center w-full relative"
            @click.stop>
            <div class="lg:flex lg:flex-wrap ">
                <div class=" lg:w-1/3">
                    <div class="bg-center bg-no-repeat bg-cover h-[13rem] lg:h-full"
                        style="background-image: url('/images/pop-up.jpg');">
                    </div>
                </div>
                <div class="p-4 lg:p-7 lg:w-2/3">
                    <p>Hi everyone! Our showroom will be temporarily closed for relocation until mid-June or until further notice.
                        <br /><br />
                        Though we’re in the midst of relocation and renovation work, we’re still contactable via WhatsApp. Just ping us and we’ll get back to you as soon as we can. 
                        <br /><br />
                        We can’t wait to share more exciting updates with all of you! See you real soon!
                    </p>
                </div>
            </div>
            <button @click="close"
                class="bg-mesh-blue animate site-btn group overflow-hidden h-[30px] w-[30px] flex items-center justify-center absolute top-0 right-0 rounded-full text-white">
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                        fill="#ffffff" />
                </svg>
            </button>
        </div>
    </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

<script>
export default {
    data() {
        return {
            popupVisible: true, //Set to false if you want to disable
            targetDate: new Date('2024-12-1'), // Change this to your desired target date yyyy-mm-dd
        };
    },
    methods: {
        close() {
            this.popupVisible = false;
            this.$emit('close');
        },
    },
    mounted() {
        document.addEventListener('click', this.close);

        // Check if the target date has passed and close the popup if it has.
        const currentDate = new Date();
        if (currentDate > this.targetDate) {
            this.close();
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close);
    },
};
</script>