<template>
  <div>
    <!-- <Popup /> -->
    <!-- header -->
    <WaBtn />
    <!-- Main Banner -->
    <MainBanner />

    <!-- end Main Banner -->
    <!-- sub-banner mobile -->
    <div class="lg:hidden">
      <div class="flex flex-wrap">
        <div v-for="(item, i) in list" :key="i" class="relative w-full px-1 py-2 md:w-1/2 text-overlay">
          <img :src="item.image" :alt="item.alt" />
          <div class="absolute inset-0 w-full h-full">
            <div
              class="absolute text-lg font-semibold text-center text-gray-800 mobile text md:text-lg xl:text-xl lg:text-base">
              {{ item.alt }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--sub-banner desktop view -->
    <div class="hidden lg:block">
      <div class="flex flex-wrap">
        <div v-for="(item, i) in list" :key="i" class="relative w-1/2 lg:w-1/4 text-overlay">
          <img :src="item.image" :alt="item.alt" class="xl:w-full" />
          <div class="absolute inset-0 w-full h-full opacity-0 overlay">
            <div
              class="absolute text-base font-semibold text-center text-black text md:text-lg xl:text-xl lg:text-base">
              <h1 class="pb-2 text-sm font-normal md:text-base">
                {{ item.title }}
              </h1>
              {{ item.alt }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- our product -->
    <div class="py-10">
      <div class="container">
        <h2 class="pb-3 text-2xl font-semibold text-center text-mesh-blue md:text-2xl lg:text-3xl">
          Our Products
        </h2>
        <div class="bg-mesh-blue h-0.5 w-12 mx-auto"></div>
        <p class="pt-3 text-xs text-gray-600">
          We at
          <span class="font-bold text-mesh-blue">"MESH-SECURE"</span> offer a
          plethora of security door and window types designed and customized to
          your needs. Each and every one of our products are rigorously tested
          and passes performance requirements in accordance to with Australian
          Standards (AS). This ensures that our products are durable with
          absolute security. Our products come in THREE DIFFERENT RANGES - each
          with it's own specifications.
        </p>

        <div class="pt-4 lg:flex">
          <div v-for="(item, i) in security" :key="i" class="pb-2 lg:pb-0 lg:px-2 lg:w-1/2">
            <h1 class="pb-3 text-lg font-medium text-gray-800">
              {{ item.alt }}
            </h1>
            <img :src="item.image" :alt="item.alt" class="" />
          </div>
        </div>

        <!-- modal section -->
        <div class="py-3 text-xs text-gray-600 md:flex md:flex-wrap md:space-y-4 ">
          <div v-for="(frame, index) in videos" :key="index" class="pb-4 md:w-1/2 lg:w-1/3 md:pb-0 ">
                <div class="lg:flex lg:flex-wrap lg:justify-center w-[300px] lg:max-w-[500px] xl:max-w-[550px] mx-auto text-gray-800 transition duration-300 ease-linear rounded-lg hover:shadow-md hover:bg-mesh-blue hover:text-white">
                  <iframe :src="frame.video" 
                      class=" h-[400px] md:h-[400px] lg:max-h-[350px] xl:max-h-[450px]">
                    </iframe>
                    <h1 class="py-3 text-lg font-semibold">{{ frame.alt }}</h1>
                </div>
            </div>
        </div>
        
        <div
          class="w-4/5 py-2 mx-auto my-2 text-center text-gray-800 transition duration-300 ease-linear border-2 rounded-lg md:w-72 hover:text-white hover:bg-mesh-blue border-mesh-blue">
          <a href="https://wa.me/60124922419" class="font-semibold texl-lg md:text-xl">Enquire Us Now</a>
        </div>
      </div>
    </div>

    <!-- product range -->
    <ProductRange />

    <!-- why choose us -->
    <div class="py-8 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <div class="pb-8">
          <h2 class="pb-3 text-2xl font-semibold text-center text-white md:text-2xl lg:text-3xl">
            Why <span class="font-bold text-mesh-blue">MESH SECURE</span> Is
            Your Only Choice?
          </h2>
          <div class="h-0.5 mx-auto bg-white w-12"></div>
        </div>
        <div class="py-3 text-xl md:flex md:justify-center">
          <div v-for="(item, i) in choose" :key="i" class="p-2">
            <img :src="item.image" :alt="item.alt" class="w-1/3 mx-auto md:w-24 lg:w-36" />
            <h1 class="pt-2 text-base font-semibold text-white lg:text-lg">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <!-- end why choose us -->

    <!-- Testimonial -->
    <div class="py-10 bg-mesh-blue">
      <div class="container">
        <div class="max-w-sm mx-auto md:max-w-full">
          <h2 class="pb-3 text-2xl font-semibold text-center text-white md:text-2xl lg:text-3xl">
            Our Testimonials
          </h2>
          <div class="bg-white h-0.5 w-12 mx-auto"></div>
          <carousel :autoplay="true" :responsive="{ 0: { items: 1, nav: false } }" class="pt-5">
            <div v-for="(item, i) in testimonial" :key="i" class="lg:max-w-3xl lg:mx-auto">
              <p class="text-white lg:text-base">
                {{ item.text }}
              </p>
              <h1 class="pt-4 font-semibold text-white lg:text-lg">
                - {{ item.client }} -
              </h1>
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <!-- End Testimonial -->

    <!-- about-us -->
    <div class="py-10 lg:flex">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="pb-3 lg:w-1/2 lg:pb-0">
            <img src="/images/about-us.jpg" alt="" class="w-4/5 mx-auto md:w-2/3 lg:w-3/4" />
          </div>
          <div class="lg:w-1/2 lg:text-left">
            <h2 class="pb-3 text-2xl font-semibold text-center lg:text-left text-mesh-blue md:text-2xl lg:text-3xl">
              About Us
            </h2>
            <div class="bg-mesh-blue h-0.5 w-12 mx-auto lg:mx-0"></div>
            <p class="pt-4 text-sm text-gray-600">
              <span class="text-lg font-bold">MESH Secure</span> <br><br>
              <b>Mesh Technology Security Screens</b> (formerly Meshtec Malaysia), is a
              privately owned entity with headquarters in Selangor, are <b>the top
                specialist in security mesh solutions</b> in Malaysia, Singapore and
              Indonesia. We have dealerships throughout the region and are now
              trading under the Mesh Secure brand. <b>Having introduced the
                original concept in 2009, we were the Pioneers of Mesh Security
                Screens in Malaysia</b>. <br /><br />
              We are constantly innovating and improving the product to meet and
              exceed customer expectations. <br /><br />
              Since 2019 we have been <b>manufacturing our products in Malaysia</b>
              thereby <b>slashing delivery times and bringing cost savings to our
                customers</b> as well as <b>extended warranty periods</b>. With our vast
              experience in development, we guarantee high quality and offer our
              customers the highest security from our products.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end about us -->


    <!-- location-->
    <div class="py-10 lg:flex">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="pb-3 lg:w-1/2 lg:pb-0">
            <h2 class="pb-3 text-2xl font-semibold text-center lg:text-left text-mesh-blue md:text-2xl lg:text-3xl">
              We’re open!
            </h2>
            <div class="bg-mesh-blue h-0.5 w-12 mx-auto lg:mx-0"></div>
            <p class="pt-4 text-lg font-bold text-center text-gray-600 lg:text-left">
              Drop by and view <span class="uppercase text-mesh-blue">our security mesh screens</span><br class="hidden md:block " /> at our new location that we now call home.
            </p>
            <div class="pt-4 text-left">
              <div class="flex flex-wrap justify-center lg:justify-start">
                <div class="w-1/12">
                  <i class="text-xl fas fa-map-pin text-mesh-blue"></i>
                </div>
                <p>1, Jalan PJU 3/38, <br />Sunway Damansara, 47810, <br /> Petaling Jaya, Selangor</p>
              </div>
              <div class="flex flex-wrap justify-center mt-4 lg:justify-start">
                <div class="w-1/12">
                  <i class="text-lg fas fa-clock text-mesh-blue"></i>
                </div>
                <p>Monday - Saturday <br />9am-5pm (4pm last walk-in)</p>
              </div>
            </div>
          </div>
          <div class=" lg:w-1/2">
            <video autoplay muted loop playsinline class="mx-auto" >
              <source src="/images/video-1.mp4" type="video/mp4" class="" >
            </video>
          </div>

        </div>
      </div>
    </div>
    <!-- end-->

    <!-- enquire now -->
    <div id="element" class="py-10 bg-gray-900">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-white">
          Enquire <span class="text-white">Us Now</span>
        </h1>
        <div class="bg-mesh-blue h-0.5 w-12 mx-auto"></div>
        <p class="pt-3 text-white">
          Fill out the form below and we'll get back to you as soon as possible
        </p>
        <div id="enquire" class="pt-6 lg:max-w-3xl lg:mx-auto">
          <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true"
            style="min-height: 530px; height: inherit; overflow: auto" width="100%" id="contactform123"
            name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5288871.html">
            <p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p>
          </iframe><!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-mesh-blue">Locate Us</h1>
        <div class="bg-mesh-blue h-0.5 w-12 mx-auto"></div>
        <div class="pt-5 lg:pt-8 lg:flex lg:items-center">
          <div class="lg:w-1/2 lg:pl-4">
            <img src="/images/main-logo.png" alt="Mesh Security Screen Sdn Bhd"
              class="mx-auto w-72 lg:mx-0 lg:w-80 md:w-72" />
            <p class="pt-1 font-semibold lg:text-left lg:text-lg">847060-K</p>
            <h1 class="pt-6 text-base font-semibold leading-tight text-gray-800 lg:text-xl lg:text-left">
              No. 1, PJU 3/38, Sunway Damansara, 47810 Petaling Jaya, Selangor
            </h1>
            <p class="pt-3 text-base font-medium text-gray-600 uppercase lg:text-left">
              <i class="fas fa-phone-alt"></i>
              Office :
              <a href="tel:+1300886374" class="hover:text-mesh-blue">1300 88 6374</a>
              <br class="md:hidden" />
              <span class="hidden px-2 md:inline-flex">|</span>
              <i class="fas fa-mobile-alt"></i>
              Mobile :
              <a href="tel:+60124922419" class="hover:text-mesh-blue">012-4922419</a>
            </p>
          </div>
          <div class="pt-6 lg:w-1/2 lg:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63740.122421665044!2d101.568733!3d3.158467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x96de7ed3f358677!2sMesh%20Security%20Screens%20(M)%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1638927481150!5m2!1sen!2smy"
              width="100%" height="300" style="border: 0" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </footer>
    <!-- Copyright -->
    <div class="py-3 bg-gray-800">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WaBtn from "@/components/WaBtn.vue";
import ProductRange from "@/components/ProductRange.vue";
import carousel from "vue-owl-carousel";
import MainBanner from "@/components/MainBanner.vue";
import Popup from "../components/Popup.vue";

export default {
  name: "Home",
  components: {
    WaBtn,
    ProductRange,
    carousel,
    MainBanner,
    Popup,
  },
  data() {
    return {
      tab: "tab-1",
      list: [
        { image: "/images/sub-1.jpg", alt: "Changi Airport Terminal 1" },
        { image: "/images/sub-2.jpg", alt: "MCMC" },
        { image: "/images/sub-3.jpg", alt: "Residential" },
        { image: "/images/sub-4.jpg", alt: "Residential" },
      ],
      submenu: [
        { tab: "tab-1", sub: "Doors" },
        { tab: "tab-2", sub: "Windows " },
      ],
      products: [
        {
          tab: "tab-1",
          type: [
            {
              image: "/images/doors/door-1.jpg",
              alt: "Hinged Door",
            },
            {
              order: "lg:order-2",
              image: "/images/doors/door-2.jpg",
              alt: "French Door",
            },
            {
              image: "/images/doors/door-3.jpg",
              alt: "Patio Enclosure",
            },
            {
              image: "/images/doors/door-4.jpg",
              alt: "Bi-Fold Door",
            },
            {
              order: "lg:order-2",
              image: "/images/doors/door-5.jpg",
              alt: "Sliding Door",
            },
            {
              image: "/images/doors/door-6.jpg",
              alt: "Mesh & Glass Door",
            },
          ],
        },
        {
          tab: "tab-2",
          type: [
            {
              image: "/images/windows/window-1.jpg",
              alt: "Awning Window",
            },
            {
              order: "lg:order-2",
              image: "/images/windows/window-2.jpg",
              alt: "Casement Window",
            },
            {
              image: "/images/windows/window-3.jpg",
              alt: "French Window",
            },
            {
              image: "/images/windows/window-4.jpg",
              alt: "Sliding Window",
            },
            {
              order: "lg:order-2",
              image: "/images/windows/window-5.jpg",
              alt: "Pool Fencing",
            },
            {
              image: "/images/windows/window-6.jpg",
              alt: "Balustrade",
            },
          ],
        },
      ],
      videos: [
      {
        video: "https://drive.google.com/file/d/1swWBli35w0ETsu07J45-_yzhsXhfTzKd/preview",
        alt: "Awning Window",
      },
      {
        video: "https://drive.google.com/file/d/1p0N_ezZh_xLxf1WXeY40Sn9lrv_ExGzB/preview",
        alt: "Bi-Fold Door",
      },
      {
        video: "https://drive.google.com/file/d/1kHO1WEj8PyyafFmsGNa_Ue02gjfyoz0U/preview",
        alt: "Casement Window",
      },
      {
        video: "https://drive.google.com/file/d/1sQEyYgrsUpZubVLtGf3j7JP5L6f6uyLY/preview",
        alt: "French Door",
      },
      {
        video: "https://drive.google.com/file/d/19ahvzFGygmfRTisrmdDYvIv4SaktVblI/preview",
        alt: "Hinged Door",
      },
      {
        video: "https://drive.google.com/file/d/1dpmeFli5OOCcSqAH-S6MfLPd2wS2ZRPO/preview",
        alt: "Sliding Door",
      },
      ],
      choose: [
        { image: "/images/icon-1.png", alt: "Free Installation" },
        { image: "/images/icon-4.png", alt: "Quality Assurance" },
        { image: "/images/icon-2.png", alt: "Direct From Factory" },
        { image: "/images/icon-3.png", alt: "Reasonable Price" },
      ],
      testimonial: [
        {
          text: "Replacing the broken grille we are very pleased with the installation of Mesh Secure Screens, after our traditional grille was damaged by a break in incident. Your company arranged for the site visit and kept in touch from order confirmation till installation of the door. Great company to deal with and will highly recommend to others.",
          client: "Mr Tay @ Subang Jaya",
        },
        {
          text: "Highly recommended! Your staffs are all very professional and good. Your company truly gives first class services.",
          client: "Joyce Lin @ Jalan But, KL",
        },
        {
          text: "Attempted break-in. I installed 2 sets of MESH-Secure sliding doors. Before Hari Raya, there was a break in, the intruder tried to pries open the MESH-SECURE door but it ended up I failure. Then they went around the rear and managed to break in by kicking the grille. Some valuables were stolen. However, I was impressed with Mesh Secure’s product because the locking frame only suffered some superficial damaged and was easily replaced. After this incident, I ordered additional panel casement window to replace the rear grille, two fixed panel and a hinged door.",
          client: "Mr Rick",
        },
      ],
      security: [
        {
          image: "/images/security-1.jpg",
          alt: "Security Doors",
        },
        {
          image: "/images/security-2.jpg",
          alt: "Security Windows",
        },
      ],
    };
  },
  methods: {
    goPrev() {
      this.$refs.carousel.goPrev();
    },
    goNext() {
      this.$refs.carousel.goNext();
    }
  }
};
</script>

<style>
.text-shadow {
  text-shadow: 2px 2px 3px #000000;
}

.overlay {
  transition: 0.5s ease;
  background-color: #ffffffd5;
}

.mobile {
  background: #ffffffd6;
  padding: 5%;
}

.text-overlay:hover .overlay {
  opacity: 1;
}

.text {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#tabs .active {
  background-color: #00ace1;
}

@media (min-width: 768px) {
  #enquire iframe {
    min-height: 520px !important;
  }
}
</style>
