<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-3">
        <img
          src="/images/main-logo.png"
          alt="Mesh Security Screens (M) Sdn Bhd"
          class="w-3/4 mx-auto md:w-1/3 md:mx-0"
        />
      </div>
      <div class="py-28 lg:flex lg:items-end md:py-32 xl:py-56">
        <div class="lg:w-2/3">
          <div class="md:w-2/3 md:text-left">
            <h1
              class="text-3xl font-bold leading-tight text-white md:text-4xl lg:text-5xl text-shadow"
            >
              MALAYSIA'S PREMIUM SECURITY MESH DOOR AND WINDOW SPECIALIST
            </h1>
            <h3
              class="pt-3 text-lg font-medium text-white lg:text-3xl text-shadow"
            >
              TESTED, PROVEN, CERTIFIED.
            </h3>
          </div>
          <!-- enquiry-btn -->
          <div class="pt-6 pb-4">
            <div class="md:flex">
              <div
                v-if="!hideEnquiry"
                class="w-4/5 py-2 mx-auto my-2 text-center rounded-lg bg-mesh-blue hover:bg-gray-800 md:w-64 md:mx-1"
              >
                <a
                  v-scroll-to="{ el: '#element', duration: 1500 }"
                  href="#"
                  class="font-semibold text-white texl-base md:text-lg lg:text-xl text-black-300"
                  >Enquire Us Now</a
                >
              </div>
              <div
                class="w-4/5 py-2 mx-auto my-2 text-center rounded-lg bg-mesh-blue hover:bg-gray-800 md:w-56 md:mx-1"
              >
                <a
                  href="https://wa.me/60124922419"
                  class="font-semibold text-white texl-base md:text-lg lg:text-xl"
                >
                  <i class="text-lg fab fa-whatsapp"></i>
                  012-4922419
                </a>
              </div>
              <div
                class="w-4/5 py-2 mx-auto my-2 text-center rounded-lg bg-mesh-blue hover:bg-gray-800 md:w-56 md:mx-1"
              >
                <a
                  href="http://meshsecure.com.my/"
                  target="_blank"
                  class="font-semibold text-white texl-base md:text-lg lg:text-xl"
                  >Website</a
                >
              </div>
            </div>
          </div>
          <!-- end enquiry-btn -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>